<template>
  <div style="height: 100%">
    <!-- loading animation -->
    <template v-if="loading">
      <v-container fluid style="height: 100%">
        <v-row align="center" justify="center" style="height: 100%">
          <v-col class="text-center">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-container>
    </template>

    <!-- data content -->
    <template v-else>
      <!-- toolbar -->
      <v-toolbar dense flat>
        <v-toolbar-title class="mr-2">{{ $t("drawer.Users") }}</v-toolbar-title>

        <v-btn text color="primary" @click="onStartUserDialog">
          <v-icon>mdi-plus</v-icon>
          <span>CREATE</span>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>

      <v-container>
        <v-row>
          <v-col>
            <v-card-title class="pt-0 px-0">
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                class="shrink"
              ></v-text-field>
            </v-card-title>
            <v-data-table
              dense
              :headers="headers"
              :items="items"
              :search="search"
              item-key="id"
              class="elevation-1"
              :loading="loading"
              :loading-text="$t('loadingInfo')"
              :items-per-page="-1"
              hide-default-footer
            >
              <template v-slot:item.actions="{ item }">
                <div class="d-flex">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="indigo"
                        v-bind="attrs"
                        v-on="on"
                        @click="showUserDetail(item)"
                      >
                        <v-icon>mdi-eye</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("view") }}</span>
                  </v-tooltip>
                  <!-- <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="blue"
                        v-bind="attrs"
                        v-on="on"
                        @click="onEditUserDialog(item)"
                      >
                        <v-icon>mdi-square-edit-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("edit") }}</span>
                  </v-tooltip> -->
                  <v-tooltip v-if="item.is_active" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="error"
                        v-bind="attrs"
                        v-on="on"
                        @click="onDeactivateUserDialog(item)"
                      >
                        <v-icon>mdi-account-cancel-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("deactivate") }}</span>
                  </v-tooltip>
                  <v-tooltip v-else bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        @click="onReactivateUserDialog(item)"
                      >
                        <v-icon>mdi-account-check-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("reactivate") }}</span>
                  </v-tooltip>
                </div>
              </template>
              <template v-slot:item.is_active="{ item }">
                <div v-if="item.is_active === true">
                  <v-chip class="ma-2" label color="primary" small>
                    ACTIVE
                  </v-chip>
                </div>
                <div v-else>
                  <v-chip class="ma-2" label color="grey" small>
                    DEACTIVATED
                  </v-chip>
                </div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </template>

    <!-- Create user -->
    <v-dialog v-model="dialogUser" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5"> USER INFORMATION </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <form class="mt-5">
                  <v-text-field
                    v-model="user.username"
                    label="Username"
                    :error-messages="userUsernameErrors"
                    required
                    @change="$v.user.username.$touch()"
                    @blur="$v.user.username.$touch()"
                    dense
                  ></v-text-field>
                  <v-text-field
                    v-model="user.email"
                    label="Email"
                    :error-messages="userEmailErrors"
                    required
                    @change="$v.user.email.$touch()"
                    @blur="$v.user.email.$touch()"
                    dense
                  ></v-text-field>
                  <v-text-field
                    v-model="user.password"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    label="New password"
                    :error-messages="userPasswordErrors"
                    required
                    @change="$v.user.password.$touch()"
                    @blur="$v.user.password.$touch()"
                    dense
                    @click:append="showPassword = !showPassword"
                  ></v-text-field>
                </form>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="onCloseUserDialog">
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="onCreateUser"
            :disabled="onCreateBtnLocked"
          >
            Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import UserDetail from "@/views/users/UserDetail.vue";
import data from "@/models/data";
import { formatTimeToClientTimezone } from "@/utils";
import {
  required,
  minLength,
  maxLength,
  email,
} from "vuelidate/lib/validators";

const passwordValidator = (value) => {
  if (!value) {
    return true; // Let required validator handle empty case
  }
  // Custom validation rule for password that allows only specific characters
  const allowedCharacters =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789_~!@#$%^&*()+-=:;<>,./?";

  const regex = new RegExp(`^[${allowedCharacters}]+$`);

  return regex.test(value);
};

export default {
  name: "UsersList",
  data: () => ({
    search: "",
    items: [
      // {
      //   id: 2,
      //   email: "example@gmail.com",
      //   name: "example",
      //   is_active: true,
      //   is_admin: false,
      //   registered_at: "2023-12-06 09:01:34.459",
      //   last_login_at: "2023-12-06 10:01:12.065",
      //   deactivated_at: null,
      // },
      // {
      //   id: 3,
      //   email: "example3@gmail.com",
      //   name: "example3",
      //   is_active: false,
      //   is_admin: false,
      //   registered_at: "2023-12-06 09:01:34.459",
      //   last_login_at: "2023-12-06 10:01:12.065",
      //   deactivated_at: null,
      // },
    ],
    dialogUser: null,
    onCreateBtnLocked: false,
    user: {
      username: null,
      email: null,
      password: null,
    },
    showPassword: false,
    item: null,
  }),
  validations: {
    user: {
      username: {
        required,
        maxLength: maxLength(32),
      },
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(64),
        passwordValidator,
      },
    },
  },
  computed: {
    headers() {
      return [
        { text: this.$t("name"), value: "name" },
        { text: "email", value: "email" },
        { text: this.$t("registeredAt"), value: "registered_at" },
        { text: this.$t("lastLoginAt"), value: "last_login_at" },
        { text: this.$t("status"), value: "is_active" },
        { text: this.$t("action"), value: "actions", sortable: false },
      ];
    },
    loading() {
      return this.$store.state.loader.loading;
    },
    userUsernameErrors() {
      const errors = [];
      if (!this.$v.user.username.$dirty) return errors;
      !this.$v.user.username.required && errors.push(this.$t("required"));
      !this.$v.user.username.maxLength &&
        errors.push(
          `Must be at most ${this.$v.user.username.$params.maxLength.max}`
        );
      return errors;
    },
    userEmailErrors() {
      const errors = [];
      if (!this.$v.user.email.$dirty) return errors;
      !this.$v.user.email.required && errors.push(this.$t("required"));
      !this.$v.user.email.email &&
        errors.push(`The email must be a genuine email address.`);
      return errors;
    },
    userPasswordErrors() {
      const errors = [];
      const passwordValidation = this.$v.user.password;

      if (!passwordValidation.$dirty) return errors;

      if (!passwordValidation.required) {
        errors.push(this.$t("required"));
      }

      if (!passwordValidation.minLength) {
        errors.push(
          `Must be at least ${passwordValidation.$params.minLength.min} characters`
        );
      }

      if (!passwordValidation.maxLength) {
        errors.push(
          `Must be at most ${passwordValidation.$params.maxLength.max} characters`
        );
      }
      if (!passwordValidation.passwordValidator) {
        errors.push(
          `Choose character within the list "a~Z 0~9 _~!@#$%^&*()+-=:;<>,./?"`
        );
      }

      return errors;
    },
  },
  methods: {
    async getUserList() {
      let items = await data.getUsers();
      items.data.data.forEach((item) => {
        if (item.registered_at) {
          item["registered_at"] = formatTimeToClientTimezone(
            item.registered_at.split(".")[0]
          );
        }
        if (item.last_login_at) {
          item.last_login_at = formatTimeToClientTimezone(
            item.last_login_at.split(".")[0]
          );
        } else {
          item.last_login_at = "-";
        }
      });
      this.items = items.data.data;
    },
    async onCreateUser() {
      this.onCreateBtnLocked = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        // valid
        console.log("this.$v.$invalid", this.$v.$invalid);
        if (this.$v.user.$invalid) {
          console.log("this.$v.user", this.$v.user.$invalid);
        }
      } else {
        const payload = {
          username: this.user.username,
          email: this.user.email,
          password: this.user.password,
        };
        try {
          const res = await data.createUser(payload);

          if (res.status !== 201) {
            this.$dialog.message.warning("Create user error.", {
              position: "top",
            });
          } else if (res.status === 201) {
            this.onCloseUserDialog();
            this.$dialog.message.success("Create user successfully.", {
              position: "top",
            });
            this.clearUserInfo();
            this.getUserList();
          } else {
            this.$dialog.message.error(this.$t("systemError"), {
              position: "top",
            });
          }
        } catch (error) {
          if (error.response.status === 400) {
            let defaultMsg = '"Create user error."';
            if (error.response.data.code === "user_exists") {
              defaultMsg = "User exists.";
            }
            this.$dialog.message.warning(defaultMsg, {
              position: "top",
            });
          } else {
            this.$dialog.message.warning("Create user error.", {
              position: "top",
            });
          }
          console.error(error);
        }
      }
      this.onCreateBtnLocked = false;
    },
    async onDeleteUser(item) {
      let res = await this.$dialog.error({
        text: `${this.$t("deleteInfo")} ${item.name} ?`,
        title: this.$t("warning"),
        actions: {
          false: this.$t("no"),
          true: {
            color: "red",
            text: this.$t("yes"),
          },
        },
      });

      if (res === true) {
        const payload = {
          dataset_group_id: item.id,
        };
        await data.deleteDatasetGroup(payload);
        this.getUserList();
      }
    },
    onStartUserDialog() {
      this.dialogUser = true;
    },
    onCloseUserDialog() {
      this.clearUserInfo();
      this.dialogUser = false;
    },
    onEditUserDialog(item) {
      this.item = item;
    },
    showUserDetail(item) {
      this.$dialog.show(UserDetail, {
        item: item,
      });
    },
    async onDeactivateUserDialog(item) {
      let res = null;
      res = await this.$dialog.error({
        text: `${this.$t("deactivateInfo")} ${item.name} ?`,
        title: this.$t("warning"),
        actions: {
          false: {
            color: "gray",
            text: this.$t("close"),
          },
          true: {
            color: "red",
            text: this.$t("deactivate"),
          },
        },
      });

      let userRes = null;

      if (res === true) {
        const payload = {
          user_id: item.id,
          active: false,
        };
        console.log(payload);
        userRes = await data.updateUser(payload);

        if (userRes.status !== 200) {
          this.$dialog.message.warning("Update user error.", {
            position: "top",
          });
          this.getUserList();
        } else if (userRes.status === 200) {
          this.onCloseUserDialog();
          this.$dialog.message.success("Update user successfully.", {
            position: "top",
          });
          this.getUserList();
        } else {
          this.$dialog.message.error(this.$t("systemError"), {
            position: "top",
          });
        }
      }
    },
    async onReactivateUserDialog(item) {
      let res = null;
      res = await this.$dialog.info({
        text: `${this.$t("reactivateInfo")} ${item.name} ?`,
        title: this.$t("Info"),
        actions: {
          false: {
            color: "gray",
            text: this.$t("close"),
          },
          true: {
            color: "primary",
            text: this.$t("reactivate"),
          },
        },
      });

      let userRes = null;

      if (res === true) {
        const payload = {
          user_id: item.id,
          active: true,
        };
        console.log(payload);
        userRes = await data.updateUser(payload);

        if (userRes.status !== 200) {
          this.$dialog.message.warning("Update user error.", {
            position: "top",
          });
          this.getUserList();
        } else if (userRes.status === 200) {
          this.onCloseUserDialog();
          this.$dialog.message.success("Update user successfully.", {
            position: "top",
          });
          this.getUserList();
        } else {
          this.$dialog.message.error(this.$t("systemError"), {
            position: "top",
          });
        }
      }
    },
    clearUserInfo() {
      this.user.username = null;
      this.user.email = null;
      this.user.password = null;
      this.$v.$reset();
    },
  },
  created() {
    this.getUserList();
  },
};
</script>

<style scope>
.v-data-table-header th {
  white-space: nowrap;
}
</style>
