<template>
  <v-card>
    <v-card-title class="text-h5"> User: {{ item.name }} </v-card-title>
    <v-card-text>
      <template v-for="(value, name, index) in item">
        <div :key="index">{{ name }}: {{ value }}</div>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ["item"],
  methods: {
    closeClick() {
      this.$emit("submit", "close");
    },
  },
};
</script>
